import DogService               from 'components/dog/dog-service';
import styles                   from 'components/dog/dog.module.scss';
import { Tapibutton }                      from 'components/Tapibutton';
import { TouchEvent, useEffect, useState } from 'react';

export const MovingDog = () => {
  const [dogGif, setDogGif] = useState<string>();
  let touchStartPosition = 0;

  const loadGif = () => {
    DogService.loadGif().then((response) => {
      setDogGif(response.data.images.original.webp);
      console.debug(response.data.images.original);

    })
  }

  const touchStart = (e: TouchEvent) => {
    e.preventDefault();
    const touch = e.touches[0] || e.changedTouches[0];
    touchStartPosition = touch.clientX;
  }

  const touchEnd = (e: TouchEvent) => {
    const touch = e.touches[0] || e.changedTouches[0];
    console.debug(touch.clientX - touchStartPosition);
    touchStartPosition = 0;

    loadGif();
  }

  useEffect(() => {
    loadGif();
  }, []);

  return (
    <div className={styles.dog}>
      <div className={styles.button}>
        <Tapibutton id={'dogbutton'} label={'New Dog'} onTrggered={loadGif} />
      </div>
      <img src={dogGif} alt={'dog'} onTouchEnd={touchEnd} onTouchStart={touchStart}/>
    </div>
  );
};
