import { useState } from 'react';
import styles       from './login.module.scss'
export const Login = () => {
  const [inputField , setInputField] = useState<any>({
    email: '',
    password: ''
  })

  const inputsHandler = (e: any) =>{
    const name = e.target.name;
    const value = e.target.value;
    setInputField( (prev: any) => ({...prev, [name]: value}))
  }


  const submitForm = (e: any) => {
    e.preventDefault();
    console.debug(inputField);


  }

  return (
    <div className={styles.login}>
      <div className={styles.headline}>
        <h1>Login</h1>
        <p>Username und Password eingeben.</p>
      </div>

      <form name={'loginForm'} onSubmit={submitForm}>
        <div>
          <label id={'email'}>Email:</label>
          <input type={'email'}
                 name="email" id={'email'}
                 onChange={inputsHandler}
                 value={inputField.email || ""}/>
        </div>

        <div>
          <label id={'password'}>Password:</label>
          <input type={'password'} name={'password'} id={'password'}
                 onChange={inputsHandler}
                 value={inputField.password || ""}/>
        </div>

        <button type={'submit'}>Login</button>
      </form>
    </div>
  );
};
