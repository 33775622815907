import DogService              from 'components/dog/dog-service';
import { Tapibutton }                      from 'components/Tapibutton';
import { TouchEvent, useEffect, useState } from 'react';
import styles                              from './dog.module.scss'
export const Dog = () => {
  const [dogImage, setDogImage] = useState<string>();
  let touchStartPosition = 0;

  const loadImage = () => {
    DogService.loadGif().then((data) => {
      console.debug(data);
    })

    DogService.loadImages().then((data) => {
      console.debug(data);
      setDogImage(data.message);
    }).catch((err) => {
      console.debug('could not load stuff', err);
    })
  }

  const touchStart = (e: TouchEvent) => {
    e.preventDefault();
    const touch = e.touches[0] || e.changedTouches[0];
    touchStartPosition = touch.clientX;
  }

  const touchEnd = (e: TouchEvent) => {
    const touch = e.touches[0] || e.changedTouches[0];
    console.debug(touch.clientX - touchStartPosition);
    touchStartPosition = 0;

    loadImage();
  }

  useEffect(() => {
    loadImage();
  }, []);

  return (
    <div className={styles.dog}>
      <div className={styles.button}>
        <Tapibutton id={'dogbutton'} label={'New Dog'} onTrggered={loadImage} />
      </div>
      <img src={dogImage} alt={'dog'} onTouchEnd={touchEnd} onTouchStart={touchStart}/>
    </div>
  );
};
