import React from 'react';
import logo                 from 'assets/img/nuri.png';

const Header = () => {
  return (
    <header>
      <img src={logo} className="logo" alt="logo" />
      <h1>Random Dog Image</h1>
      <img src={logo} className="logo" alt="logo" />
    </header>
  );
}

export default Header
