const DogService = {
  loadImages: () => {
    return fetch('https://dog.ceo/api/breeds/image/random').then((response) => response.json());
  },
  loadGif: () => {
    return fetch('https://api.giphy.com/v1/gifs/random?api_key=ogPuAVX4Secq4yM6fBl6dL096jHilZx4&tag=dogs&rating=g').then((response) => response.json());
  }
}

export default DogService
