import { ErrorPage }                           from 'components/core/ErrorPage';
import Root                                    from 'components/core/Root';
import { Dog }                                 from 'components/dog/Dog';
import { MovingDog }                           from 'components/dog/MovingDog';
import { Login }                               from 'components/login/Login';
import { Tapibutton }                          from 'components/Tapibutton';
import React                                   from 'react';
import ReactDOM                                from 'react-dom/client';
import './index.css';
import reportWebVitals                         from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Dog />
      },
      {
        path: 'gif',
        element: <MovingDog />
      },
      {
        path: 'login',
        element: <Login />
      }
    ],
  },
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
