import Footer     from 'components/core/Footer';
import Header     from 'components/core/Header';
import { Outlet } from 'react-router-dom';

const Root = () => {
  return (
    <div className={'container'}>
      <Header />
      <div className={'outlet'}>
        <Outlet />
      </div>

    </div>
  );
}

export default Root;
